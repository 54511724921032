import HeroSection from "./HeroSection";
import Features from "./Features";
import CallToAction from "./CallToAction";

function Home() {
  return (
    <div className="mb-40 space-y-20">
      <HeroSection />
      <Features />
      <CallToAction />
    </div>
  );
}

export default Home;
