import 'react-responsive-carousel/lib/styles/carousel.min.css';

var Carousel = require('react-responsive-carousel').Carousel;


function WipDemo() {

  const onChange = () => { };

  const onClickItem = () => { };

  const onClickThumb = () => { }

  setInterval(() => {
    window.location.reload();
  }, 60 * 1000);

  return (
    <Carousel
      showArrows={true}
      onChange={onChange}
      onClickItem={onClickItem}
      onClickThumb={onClickThumb}
      showThumbs={false}
      autoFocus={true}
      interval={5000}
      transitionTime={0}
      stopOnHover={false}
      autoPlay={true}
      infiniteLoop={true}
    >
      <div> <img alt="ad" src="images/ads/slow-market.jpg" style={{height: '100%'}} /> </div>
      <div> <img alt="ad" src="images/ads/vintage-finds.jpg" style={{height: '100%'}}/> </div>
      <div> <img alt="ad" src="images/ads/vintage-makers.jpg" style={{height: '100%'}} /> </div>
      <div> <img alt="ad" src="images/ads/pegboard.jpg" style={{height: '100%'}} /> </div>
      <div> <img alt="ad" src="images/ads/apait.jpg" style={{height: '100%'}} /> </div>
      <div> <img alt="ad" src="images/ads/art-market.jpg" style={{height: '100%'}} /> </div>
      <div> <img alt="ad" src="images/ads/elevator-etiquette.jpg" style={{height: '100%'}} /> </div>
      <div> <img alt="ad" src="images/ads/free-chapel-college.jpg" style={{height: '100%'}} /> </div>
      <div> <img alt="ad" src="images/ads/free-chapel-college-02.jpg" style={{height: '100%'}} /> </div>
    </Carousel>
  );
};

export default WipDemo;

// Don't forget to include the css in your page 
// <link rel="stylesheet" href="carousel.css"/>
// Begin DemoSliderControls
