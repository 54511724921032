import LoadingSpinner from "./components/LoadingSpinner";

function LoadingView() {
  return (
    <div className="flex p-10 items-center justify-center">
      <LoadingSpinner />
    </div>
  );
}

export default LoadingView;
