import {
  getAuth,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { GraphQLClient } from "graphql-request";
import { createContext, MutableRefObject, useEffect, useRef, useState } from "react";
import { GqlHost } from "./config";


export type AppUser = {
  id: string;
  name?: string;
  email: string;
  hasPaymentInfo: boolean;
  isAdmin: boolean;
}

type AuthContextType = {
  user: MutableRefObject<AppUser | null>,
  authToken: string | undefined,
  gqlClient: MutableRefObject<GraphQLClient | null>,
  logOut: () => Promise<void>,
  loading: boolean,
}

export const AuthContext = createContext({} as AuthContextType);

const AuthProvider = ({ children }: any) => {
  const auth = getAuth();
  const gqlClient = useRef<GraphQLClient | null>(null);
  const user = useRef<AppUser | null>(null);
  const [authToken, setAuthToken] = useState<string|undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const logOut = async () => {
    setLoading(true);

    await signOut(auth);

    setLoading(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      const token = await currentUser?.getIdToken()
      console.log(token);

      setAuthToken(token);

      gqlClient.current = new GraphQLClient(GqlHost, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const appUser = await syncUser(gqlClient, currentUser);

      user.current = appUser;

      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const syncUser = async (gqlClient: any, user: any): Promise<AppUser> => {
    const mutation = `
      mutation Mutation($email: String, $name: String) {
        sync(
          email: $email
          name: $name
        ) {
          name
          email
          hasPaymentInfo
        }
      }
    `;

    const variables = {
      email: user.email,
      name: user.displayName
    }

    const resp = await gqlClient.current.request(mutation, variables)

    return resp.sync;
  };

  const authValue = {
    user,
    authToken,
    gqlClient,
    logOut,
    loading,
  };

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
