import { useState } from "react";
import Container from "./Container";

export default function InquiryForm() {
  const [showToast, setShowToast] = useState(false)

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setShowToast(true);

    const payload = {
      name: event.target['name']?.value,
      company: event.target['company']?.value,
      email: event.target['emailbang']?.value,
      message: event.target['message']?.value,
      honeypot: event.target['email']?.value,
    };

    try {
      const res = await fetch('https://api.pegboard.ai/email-signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
    } catch (e) {
      console.error(e);
    }
  };

  const successToast = (
    <div
      id="toast-simple"
      className="m-auto my-4 flex items-center py-4 mb-24 mt-12 px-4 space-x-4 rtl:space-x-reverse bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
      role="alert"
    >
      <div className="text-green-600 text-sm font-bold m-auto">Great! We'll be in contact soon.</div>
    </div>
  );

  return (
    <div className="relative">
      <div
        aria-hidden="true"
        className="dark:opacity-20 absolute inset-0 m-auto grid h-max w-full grid-cols-2 -space-x-52 opacity-40"
      >
        <div className="dark:from-blue-700 h-56 bg-gradient-to-br from-primary to-purple-400 blur-[106px]"></div>
        <div className="dark:to-indigo-600 h-32 bg-gradient-to-r from-cyan-400 to-sky-300 blur-[106px]"></div>
      </div>

      <Container>
        <div className="relative">
          <div className="flex items-center justify-center -space-x-2"></div>
          <div className="m-auto mt-6 space-y-6 md:w-8/12 lg:w-7/12">
            <h1 className="dark:text-white text-center text-3xl font-bold text-gray-800">
              Get Started
            </h1>

            <div className="flex flex-wrap justify-center gap-6">
              <div className="mx-auto flex flex-col items-center space-y-3 md:space-y-0 w-full">
                {
                  showToast
                    ? successToast
                    : (
                    <form onSubmit={onSubmit} className="w-full">
                      <div className="relative w-full mt-4">
                        <label
                          htmlFor="name"
                          className="dark:text-gray-300 mb-2 hidden text-sm font-medium text-gray-900"
                        >
                          Your Name
                        </label>
                        <input
                          className="focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 block h-12 w-full rounded-lg border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900"
                          placeholder="Your Name"
                          type="text"
                          id="name"
                          required={true}
                        />
                      </div>

                      <div className="relative w-full mt-4">
                        <label
                          htmlFor="company"
                          className="dark:text-gray-300 mb-2 hidden text-sm font-medium text-gray-900"
                        >
                          Company Name
                        </label>
                        <input
                          className="focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 block h-12 w-full rounded-lg border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900"
                          placeholder="Company Name"
                          type="text"
                          id="company"
                        />
                      </div>

                      <div className="relative w-full mt-4">
                        <label
                          htmlFor="emailbang"
                          className="dark:text-gray-300 mb-2 hidden text-sm font-medium text-gray-900"
                        >
                          Email address
                        </label>

                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <svg
                            className="dark:text-gray-400 h-5 w-5 text-gray-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                          </svg>
                        </div>

                        <input
                          className="focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 block h-12 w-full rounded-lg border border-gray-300 bg-gray-50 p-3 pl-10 text-sm text-gray-900"
                          placeholder="Email address"
                          type="email"
                          id="emailbang"
                          required={true}
                        />
                      </div>

                      <div className="relative w-full pt-4">
                        <label
                          htmlFor="message"
                          className="dark:text-gray-300 mb-2 text-sm font-medium text-gray-600"
                        >
                          Brief Message
                        </label>

                        <textarea
                          className="focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900"
                          rows={4}
                          maxLength={255}
                          id="message"
                        >I'm interested in advertising!</textarea>
                      </div>

                      <div className="pt-6">
                        <button
                          type="submit"
                          className="hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-12 w-full cursor-pointer rounded-lg border-transparent bg-primary px-5 text-center text-sm font-medium text-white focus:ring-4"
                        >
                          <span className="dark:text-dark relative text-sm font-semibold text-white">
                            Send
                          </span>
                        </button>
                      </div>

                      {/* Honeypot */}
                      <label className="absolute top-0 left-0 w-0 h-0 opacity-0 p-0 m-0" htmlFor="email"></label>
                      <input className="absolute top-0 left-0 w-0 h-0 opacity-0 p-0 m-0" autoComplete="off" type="email" id="email" name="email" placeholder="Your e-mail here" tabIndex={-1} />
                    </form>
                )}
              </div>

            </div>

          </div>
        </div>
      </Container>
    </div>
  );
}
