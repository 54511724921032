import { Check, CircleAlert, CircleDot } from "lucide-react";

const colors: Record<string, any> = {
  green: {
    bg: 'bg-green-100',
    bdr: 'border-green-200',
    text: 'text-green-800',
    dot: 'bg-green-600',
  },
  red: {
    bg: 'bg-red-100',
    bdr: 'border-red-200',
    text: 'text-red-800',
    dot: 'bg-red-600',
  },
  yellow: {
    bg: 'bg-yellow-100',
    bdr: 'border-yellow-200',
    text: 'text-yellow-800',
    dot: 'bg-yellow-600',
  },
  gray: {
    bg: 'bg-gray-100',
    bdr: 'border-gray-200',
    text: 'text-gray-800',
    dot: 'bg-gray-600',
  },
  orange: {
    bg: 'bg-orange-100',
    bdr: 'border-orange-200',
    text: 'text-orange-800',
    dot: 'bg-orange-600',
  },
}

function StatusBadge(props: any) {
  const { label } = props;
  
  let color = props.color;

  if (!color) {
    color = 'gray';

    switch (true) {
      case label === 'Pending':
      case label === 'Inactive':
        color = 'gray';
        break;
      case label === 'Rejected':
        color = 'red';
        break;
      case label === 'Approved':
      case label === 'Active':
        color = 'green';
        break;
      case label === 'Live':
        color = 'green';
        break;
    }
  }

  const { bg, bdr, text } = colors[color ?? 'gray'];

  let icon = null;
  switch (true) {
    case label === 'Rejected':
      icon = <CircleAlert size={14} />
      break;
    case label === 'Pending':
      icon = <CircleDot size={14} />
      break;
    case label === 'Live':
      icon = <Check size={16} />
      break;
    default:
      break;
  }

  return (
    <div className="flex">
      <div className={`flex items-center justify-center overflow-hidden rounded-md py-[2px] px-2 border ${bdr} ${bg} ${text}`}>
        { icon }
        <div className={`pl-1 font-medium text-sm`}>{ label }</div>
      </div>
    </div>
  );
}

export default StatusBadge
