import { useQuery } from "@tanstack/react-query";
import { useContext } from 'react';
import Container from '../Container';
import { AuthContext } from "../AuthProvider";
import LoadingSpinner from "../components/LoadingSpinner";
import StatusBadge from "../StatusBadge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"


function AdminDashboard() {
  const tabTriggerClass = 'data-[state=active]:bg-white data-[state=active]:text-black data-[state=active]:font-bold';
  const tabContentClass = 'bg-white p-4 rounded-lg';

  return (
    <Container>
      <div className="flex flex-row justify-between items-center pb-2 mb-6">
        <div className="text-2xl font-semibold">Admin</div>
      </div>

      <Tabs defaultValue="live">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="live" className={tabTriggerClass}>Live Ads</TabsTrigger>
          <TabsTrigger value="pending" className={tabTriggerClass}>Pending Ads</TabsTrigger>
          <TabsTrigger value="rejected" className={tabTriggerClass}>Rejected Ads</TabsTrigger>
        </TabsList>
        <TabsContent value="live" className={tabContentClass}><ApprovedAds /></TabsContent>
        <TabsContent value="pending" className={tabContentClass}><PendingAds /></TabsContent>
        <TabsContent value="rejected" className={tabContentClass}><RejectedAds /></TabsContent>
      </Tabs>

    </Container>
  );
}

function ApprovedAds() {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      adminApprovedAds {
        id
        name
        paid
        status
        published
        target {
          url
        }
        image {
          url
        }
        video {
          url
        }
      }
    }
  `;

  const getAds = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending, error } = useQuery({ queryKey: ['adminApprovedAds'], queryFn: getAds, retry: 0 })

  let loading = null;
  if (isPending) {
    loading =  (
      <div className="flex flex-col items-center justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  let ads = [];
  if (data) {
    ads = (data as any).adminApprovedAds.map((item: any) => {
      return <AdRow data={item} key={item.id} />
    });
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-2 mb-4">
        <div className="text-lg font-semibold">Live Ads</div>
      </div>

      { loading }
      { ads }
    </div>
  );
}

function RejectedAds() {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      adminRejectedAds {
        id
        name
        paid
        status
        published
        target {
          url
        }
        image {
          url
        }
      }
    }
  `;

  const getAds = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending } = useQuery({ queryKey: ['rejectedAds'], queryFn: getAds, retry: 0 })

  let loading = null;
  if (isPending) {
    loading =  (
      <div className="flex flex-col items-center justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  let ads = [];
  if (data) {
    ads = (data as any).adminRejectedAds.map((item: any) => {
      return <AdRow data={item} key={item.id} />
    });
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-2 mb-4">
        <div className="text-lg font-semibold">Rejected Ads</div>
      </div>

      { loading }

      <div className="divide-y">
        { ads }
      </div>
    </div>
  );
}


function PendingAds() {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      adminPendingAds {
        id
        name
        paid
        status
        published
        target {
          url
        }
        image {
          url
        }
      }
    }
  `;

  const getAds = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending } = useQuery({ queryKey: ['pendingAds'], queryFn: getAds, retry: 0 })

  let loading = null;
  if (isPending) {
    loading =  (
      <div className="flex flex-col items-center justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  let ads = [];
  if (data) {
    ads = (data as any).adminPendingAds.map((item: any) => {
      return <AdRow data={item} key={item.id} />
    });
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-2 mb-4">
        <div className="text-lg font-semibold">Pending Ads</div>
      </div>

      { loading }

      <div className="divide-y">
        { ads }
      </div>
    </div>
  );
}

function AdRow(props: any) {
  const { data } = props;
  const { name, target, image } = data;

  return (
    <a
      href={`/admin/ad-review?adId=${data.id}`}
      className="flex w-full bg-white p-3 rounded-lg"
    >
      <div className="overflow-hidden rounded-lg shrink-0 h-40 aspect-adv">
        <div
          className='h-full w-full bg-cover bg-center bg-no-repeat'
          style={{backgroundImage: `url(${image?.url})`}}>
        </div>
      </div>

      <div className="pl-4 grow">
        <div>
          <div className="font-bold">{ name }</div>
          <div className="text-gray-800 font-semibold text-sm">{ target?.url }</div>
        </div>

        <div className="pt-4 flex flex-wrap">
          <div className="pr-2 pb-2">
            <StatusBadge label={data.status} />
          </div>
        </div>
      </div>
    </a>
  );
}

export default AdminDashboard;
