import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "./Container";
import { AuthContext } from "./AuthProvider";
import LoadingSpinner from "./components/LoadingSpinner";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { StripePubKey } from "./config";


const stripePromise = loadStripe(StripePubKey);

function ReviewAdPayment() {
  const { gqlClient } = useContext(AuthContext);
  const navigate = useNavigate();

  const document = `
    query Query {
      paymentMethods {
        brand
        last4
      }
    }
  `;

  const getPaymentMethods = () => gqlClient.current?.request({ document })

  const { data, isPending } = useQuery<any>({ queryKey: ['paymentMethods'], queryFn: getPaymentMethods, retry: 0 })

  const hasPaymentMethod = data?.paymentMethods?.length;

  if (isPending) {
    return (
      <div className="flex p-4 justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  // temporarily disable immediate payments
  // if (hasPaymentMethod) {
    return (
      <Container>
        <div className="text-center">
          <div className="text-xl font-semibold pb-2">Ad Submitted!</div>
          <div className="text-sm font-semibold pb-10">An invoice will be created once the advertisement goes live.</div>

          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50"
              onClick={() => navigate('/dashboard')}
          >
            Go to Dashboard
          </button>
        </div>
      </Container>
    )
  // }

  // return <ReviewAdRequestPaymentInfo />;
}

function ReviewAdRequestPaymentInfo() {
  const { gqlClient } = useContext(AuthContext);

  const fetchClientSecret = useCallback(async () => {
    const mutation = `
      mutation Mutation($returnUrl: String!) {
        createCheckoutSession(returnUrl: $returnUrl)
      }
    `;

    const currUrl = window.location.href;

    const variables = {
      returnUrl: `${currUrl}/success`,
    };

    const resp = await gqlClient.current?.request(mutation, variables);

    return (resp as any).createCheckoutSession;
  }, [gqlClient]);

  const options = { fetchClientSecret };

  return (
    <>
      <Container>
        <div className="text-center">
          <div className="text-xl font-semibold pb-1">Add a payment method</div>
          <div className="text-sm text-gray-500 pb-4">Add your payment information now so your ad can go live as soon as it's approved.</div>
        </div>
      </Container>

      <div className="rounded-xl overflow-hidden">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </>
  );
}

export function ReviewAdPaymentSuccess() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get("session_id")

  const { gqlClient } = useContext(AuthContext);

  const mutation = useMutation({
    mutationFn: async () => {
      const mutation = `
        mutation Mutation($sessionId: String!) {
          attachPaymentInfo(
            sessionId: $sessionId
          )
        }
      `;

      const variables = {
        sessionId,
      };

      await gqlClient.current?.request(mutation, variables);
    },
    onSuccess: () => {
    },
    onError: (e) => {
      console.log(e);
      alert(e);
    },
    retry: 0
  });

  useEffect(() => {
    mutation.mutate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <div className="flex flex-col items-center justify-center">

        { mutation.isPending ? (
          <>
            <div className="text-xl font-semibold pb-4">Verifying Payment Info</div>
            <LoadingSpinner />
          </>
        ) : (
          <>
            <div className="text-xl font-semibold pb-4">Payment Info Verified!</div>

            <div className="p-4 mb-8 rounded-lg bg-green-50" role="alert">
              <div className="text-sm">You will be billed only after your ad goes live.</div>
            </div>

            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50"
                onClick={() => navigate('/dashboard')}
            >
              Go to Dashboard
            </button>
          </>
        )}
      </div>
    </Container>
  );
}

export default ReviewAdPayment;
