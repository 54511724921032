import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Container from "./Container";
import { AuthContext } from "./AuthProvider";
import LoadingSpinner from "./components/LoadingSpinner";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { Loader2 } from "lucide-react";
import { StripePubKey } from "./config";


const stripePromise = loadStripe(StripePubKey);


function AccountAddPayment() {
  const { gqlClient } = useContext(AuthContext);

  const fetchClientSecret = useCallback(async () => {
    const mutation = `
      mutation Mutation($returnUrl: String!) {
        createCheckoutSession(returnUrl: $returnUrl)
      }
    `;

    const currUrl = window.location.href;

    const variables = {
      returnUrl: `${currUrl}/success`,
    };

    const resp = await gqlClient.current?.request(mutation, variables);

    return (resp as any).createCheckoutSession;
  }, [gqlClient]);

  const options = { fetchClientSecret };

  return (
    <Container>
      <Link to={`/dashboard/account`} className="flex items-center pr-4 py-1 mb-5 font-semibold inline-flex text-sm text-gray-800">
        <svg className="w-5 h-5 rtl:rotate-180 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
        </svg>
        Back to Account
      </Link>

      <div className="text-xl font-semibold pb-2">Add a payment method</div>

      <div className="rounded-xl overflow-hidden">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </Container>
  );
}

export function AccountAddPaymentSuccess() {
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get("session_id")

  const { gqlClient } = useContext(AuthContext);

  const mutation = useMutation({
    mutationFn: async () => {
      const mutation = `
        mutation Mutation($sessionId: String!) {
          attachPaymentInfo(
            sessionId: $sessionId
          )
        }
      `;

      const variables = {
        sessionId,
      };

      await gqlClient.current?.request(mutation, variables);
    },
    onSuccess: () => {
      setTimeout(() => {
        window.location.href = '/dashboard/account';
      }, 2000);
    },
    onError: (e) => {
      console.log(e);
      alert(e);
    },
    retry: 0
  });

  useEffect(() => {
    mutation.mutate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <div className="flex flex-col items-center justify-center">

        { mutation.isPending ? (
          <>
            <div className="text-xl font-semibold pb-4">Verifying Payment Info</div>
            <LoadingSpinner />
          </>
        ) : (
          <>
            <div className="text-xl font-semibold pb-6">Payment Info Verified!</div>

            <Link to={`/dashboard/account`} className="flex items-center pr-4 py-2 font-semibold inline-flex text-sm text-gray-800">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Redirecting to Account
            </Link>

          </>
        )}
      </div>
    </Container>
  );
}

export default AccountAddPayment;
