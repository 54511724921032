import { ApiHost } from './config'
import StartWithBizDetails from './components/StartWithBizDetails';
import LoadingSpinner from './components/LoadingSpinner';
import { useSearchParams } from 'react-router-dom';
import {
  useQuery
} from '@tanstack/react-query'
import SignupOptions from './SignupOptions';

// const bizData = {
//   name: 'Work in Progress',
//   address: '2944 Randolph Ave Unit 1',
//   city: 'Costa Mesa',
//   province: 'CA',
//   postcode: '92626',
//   googleMapsUrl: 'https://maps.app.goo.gl/JRBXGmm64wfSDJCK7',
//   images: [
//     'https://pegboard-backend-mediabucket-swu9ddbc4nvq.s3.us-west-2.amazonaws.com/orgs/org_01HV4GNA4Y3W6JFEA55KMSGK5Z/biz_01HXPZK05AAG9096087GBPSTT8/01HXPZKMDB9378ZYZMDQAPR59H.jpg',
//     'https://pegboard-backend-mediabucket-swu9ddbc4nvq.s3.us-west-2.amazonaws.com/orgs/org_01HV4GNA4Y3W6JFEA55KMSGK5Z/biz_01HXPZK05AAG9096087GBPSTT8/01HXPZM7YGT2RCPJT0JWNVQH37.jpg',
//     'https://pegboard-backend-mediabucket-swu9ddbc4nvq.s3.us-west-2.amazonaws.com/orgs/org_01HV4GNA4Y3W6JFEA55KMSGK5Z/biz_01HXPZK05AAG9096087GBPSTT8/01HXPZMDG6P8GFZHTAXGTF06QJ.jpg',
//     'https://pegboard-backend-mediabucket-swu9ddbc4nvq.s3.us-west-2.amazonaws.com/orgs/org_01HV4GNA4Y3W6JFEA55KMSGK5Z/biz_01HXPZK05AAG9096087GBPSTT8/01HXPZMH53Y3SEQX6Y90FFJV50.jpg',
//     'https://pegboard-backend-mediabucket-swu9ddbc4nvq.s3.us-west-2.amazonaws.com/orgs/org_01HV4GNA4Y3W6JFEA55KMSGK5Z/biz_01HXPZK05AAG9096087GBPSTT8/01HXPZMN8JT6T5N36JKB5QEMR9.jpg'
//   ]
// }

function StartWithHost() {
  const [searchParams] = useSearchParams();

  const bizId = searchParams.get("host") || 'wipcm'

  const getBiz = async () => {
    const resp = await fetch(`${ApiHost}/start?host=${bizId}`)
    return resp.json();
  };

  const { data, isLoading, error } = useQuery({ queryKey: ['biz'], queryFn: getBiz, retry: 0 })

  let bizInfo = undefined;
  if (isLoading) {
    bizInfo = (
      <div className="flex justify-center align-center">
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    bizInfo = null;
  }

  if (data) {
    bizInfo = (
      <div className="align-center">
        <StartWithBizDetails data={data} />
      </div>
    );
  }

  return (
    <div className="relative m-auto w-full md:w-2/3 lg:w-1/2 space-y-4 pt-12">
      <div className="flex flex-col">
        { bizInfo }

        <div className="pt-12">
          <SignupOptions />
        </div>
      </div>
    </div>
  );
}

export default StartWithHost;
