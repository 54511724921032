import { useQuery } from "@tanstack/react-query";
import { useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Container from './Container';
import { AuthContext } from "./AuthProvider";
import LoadingSpinner from "./components/LoadingSpinner";
import StatusBadge from "./StatusBadge";


function Dashboard() {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code')
  switch (true) {
    case code === 'ad-success':
      break;
    case code === 'ad-needs-payment':
      break;
  }

  return (
    <Container>
      <div className="pb-4">
        <YourAds />
      </div>

      {/*
      <div className="pt-10">
        <YourBoards />
      </div>
      */}
    </Container>
  );
}

function YourAds() {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      myAds {
        id
        name
        status
        target {
          url
        }
        image {
          url
        }
        video {
          url
        }
      }
    }
  `;

  const getAds = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending } = useQuery({ queryKey: ['myAds'], queryFn: getAds, retry: 0 })

  let loading = null;
  if (isPending) {
    loading =  (
      <div className="flex flex-col items-center justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  let ads = [];
  if (data) {
    ads = (data as any).myAds.map((item: any) => {
      return <AdRow data={item} key={item.id} />
    });

    if (!ads.length) {
      ads = [(
        <div className="text-center py-5 text-sm text-gray-500" key='no-ads'>You have no ads yet</div>
      )];
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-2">
        <div className="text-2xl font-semibold">Your Ads</div>
        <Link
          to={`/dashboard/ads/new`}
          className="items-center px-3 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50"
        >Create a New Ad</Link>
      </div>

      { loading }
      { ads }
    </div>
  );
}

function AdRow(props: any) {
  const { data } = props;

  return (
    <Link to={`/dashboard/ads/details/${data.id}`}>
      <div className="flex flex-row items-start py-4">

        <div className="overflow-hidden rounded-lg w-24 h-24 flex-shrink-0">
          <div
            className='w-full h-full bg-cover bg-top bg-no-repeat'
            style={{backgroundImage: `url(${data.image?.url})`}}>
          </div>
        </div>

        <div className="pl-4">
          <div className="font-bold flx">{ data.name }</div>
          { !!data.status && (
            <div className="mt-2">
              <StatusBadge label={data.status} />
            </div>
          ) }
        </div>
      </div>
    </Link>
  );
}

/*
function YourBoards() {
  const navigate = useNavigate();
  const onClick = () => navigate('create-ad');

  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      myBoards {
        id
        name
      }
    }
  `;

  const getBoards = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending, error } = useQuery({ queryKey: ['myBoards'], queryFn: getBoards, retry: 0 })

  let loading = null;
  if (isPending) {
    loading =  (
      <div className="flex flex-col items-center justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  let boards = [];
  if (data) {
    boards = (data as any).myBoards.map((item: any) => {
      return <AdRow data={item} key={item.id} />
    });

    if (!boards.length) {
      boards = [(
        <div className="text-center py-5 text-sm text-gray-500" key='no-ads'>You have no boards yet</div>
      )];
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-2">
        <div className="text-2xl font-semibold">Your Boards</div>
        <button
          type="button"
          className="items-center px-3 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-green-500 whitespace-nowrap disabled:opacity-50"
        >Add a Board</button>
      </div>

      { loading }
      { boards }
    </div>
  );
}
*/


export default Dashboard;
