import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { ApiHost } from './config';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';

const Carousel = require('react-responsive-carousel').Carousel;


function ImageItem({source, qrCode, isSelected}: any) {
  return (
    <div className='w-full aspect-adv'>
      <img alt="ad" src={source} style={{height: '100%'}} />

      { qrCode }
    </div>
  );
}

function VideoItem({source, poster, qrCode, isSelected}: any) {
  return (
    <div className='w-full aspect-adv'>
      <div>
        <video
          ref={(ref) => {
            if (!ref) {
              return;
            }

            if (isSelected) {
              ref.currentTime = 0;
              ref.play();
            } else {
              ref.currentTime = 0;
              ref.pause();
            }
          }}
          controls={false}
          muted
          loop
          poster={poster}
          className="w-full h-full"
        >
          <source
            src={source}
            type="video/mp4"
          ></source>
        </video>
      </div>

      { qrCode }
    </div>
  );
}

export default function DisplayBoard() {
  const { boardId } = useParams();

  document.body.classList.add('bg-gray-800');

  const getAds = async () => {
    const resp = await fetch(`${ApiHost}/boards/${boardId}/ads`);
    return resp.json();
  };

  const { data, isPending, error } = useQuery<any>({ queryKey: [`adsForBoard-${boardId}`], queryFn: getAds, retry: 0 })

  if (error) {
    return <div className="text-center py-8 px-4 text-gray-700">Error loading board</div>;
  }

  if (isPending) {
    return <div className="text-center py-8 px-4 text-gray-700">Loading board</div>;
  }

  const comps: Array<any> = [];

  if (data) {
    const ads = data['ads'] || []

    ads.forEach((ad: any) => {
      let qrCode: any = null;

      if (ad.target?.url) {
        const isOurAd = ad.id === 'ad_01HYSKVM3G0WPTC1HCTGRAF1AR';
        const alignClass = isOurAd ? 'justify-center pb-24' : 'justify-end';

        qrCode = (
          <div className={`flex w-full p-6 ${alignClass} absolute bottom-0`}>
            <div className='overflow-hidden rounded-lg'><QRCode value={ad.target.url} bgColor='#ffffff' size={250} /></div>
          </div>
        );
      }

      const key = `ad-${ad.id}`

      if (ad.video?.url) {
        comps.push(<VideoItem key={key} source={ad.video?.url} poster={ad.image?.url} qrCode={qrCode} />);
      } else if (ad.image?.url) {
        comps.push(<ImageItem key={key} source={ad.image?.url} qrCode={qrCode} />);
      }
    });
  }

  return (
    <>
      <Carousel
        showArrows={true}
        showThumbs={false}
        autoFocus={true}
        interval={15000}
        transitionTime={0}
        stopOnHover={false}
        autoPlay={true}
        infiniteLoop={true}
        showIndicators={false}
        renderItem={(item: any, props: any) => <item.type {...item.props} {...props} />}
      >
        { comps }
      </Carousel>
    </>
  );
};
