import { useContext } from 'react';
import { AuthContext } from "./AuthProvider";
import { Navigate, Outlet } from 'react-router-dom';
import AppFooter from './AppFooter';
import Container from './Container';
import { Menu, CircleX } from 'lucide-react';
import { useState } from 'react';


export default function AdminRoutes() {
  const { loading, user } = useContext(AuthContext);

  if (loading) {
    return (<></>);
  }

  if (user.current?.isAdmin) {
    return <Navigate to='/' />;
  }

  return (
    <div className="dark:bg-gray-900 bg-white">
      <AdminBgGradient />
      <AdminRoutesHeader />
      <div className="relative m-auto w-full md:w-2/3 lg:w-1/2 space-y-4 pt-10">
        <Outlet />
      </div>
      <AppFooter />
    </div>
  )
}


function HamburgerMenu() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <button onClick={() => setShowMenu(!showMenu)}
        className="hidden py-3 pl-2 sm:hidden"
      >
        <Menu />
      </button>

      <div
        className="block py-3 pl-2 sm:block space-x-2"
      >
        <a href="/admin" className="font-medium py-2 px-2 text-sm">Ads</a>
        <a href="/admin/boards" className="font-medium py-2 px-2 text-sm">Boards</a>
        <a href="/admin" className="font-medium py-2 px-2 text-sm">Dashboard</a>
      </div>
      {
        !!showMenu && (
          <div
            className="fixed top-0 right-0 bottom-0 left-0 bg-white"
          >
            <div className="flex justify-end">
              <button className='p-3' onClick={() => setShowMenu(false)}>
                <CircleX />
              </button>
            </div>
            <div className="text-center flex flex-col space-y-4 pt-10">
              <a href="/admin/boards" className="text-2xl font-medium py-2 px-4">Ads</a>
              <a href="/admin/boards" className="text-2xl font-medium py-2 px-4">Boards</a>
              <a href="/admin" className="text-2xl font-medium py-2 px-4">Dashboard</a>
            </div>
          </div>
        )
      }
    </>
  );
}

function AdminRoutesHeader() {
  return (
    <nav className="dark:border-white/5 m-auto z-10 w-full lg:w-2/3 border-b border-black/5 lg:border-transparent">
      <Container>
        <div className="relative flex flex-wrap items-center justify-between gap-6 md:gap-0">
          <div className="relative z-20 flex w-full justify-between md:px-0">
            <a
              href="/admin"
              aria-label="logo"
              className="flex items-center space-x-2 py-3"
            >
              <div aria-hidden="true" className="flex space-x-1">
                <div className="dark:bg-white h-3 w-3 rounded-full bg-gray-900"></div>
                <div className="h-6 w-1 bg-primary"></div>
              </div>
              <span className="text-l dark:text-white font-bold text-gray-900">
                PegBoard.ai
              </span>
            </a>

            <HamburgerMenu />

          </div>
        </div>
      </Container>
    </nav>
  )
}

function AdminBgGradient() {
  return (
    <div
      aria-hidden="true"
      className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40"
    >
      <div className="h-24 bg-gradient-to-br from-red-700 to-green-400 blur-[106px]"></div>
      <div className="h-12 bg-gradient-to-r from-orange-400 to-yellow-300 blur-[106px]"></div>
    </div>
  )
}
