import { useQuery, useMutation } from "@tanstack/react-query";
import { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import Container from './Container';
import LoadingSpinner from "./components/LoadingSpinner";
import { PaymentIcon } from 'react-svg-credit-card-payment-icons';
import AccountChargesTable from "./Account-Charges-Table";
import { Loader2 } from "lucide-react";
import { Link } from 'react-router-dom';



function Account() {
  const { user, logOut } = useContext(AuthContext);

  return (
    <Container>
      <div className="flex justify-between">
      <div className="text-xl font-medium">Account</div>
      <button
        className="text-red-700 font-semibold text-sm"
        onClick={async () => {
          if (window.confirm('Sign out?')) {
            await logOut();
            window.location.href = '/';
          }
        }}
      >
        Sign Out
      </button>

      </div>

      <div className="font-medium mt-2">{user.current?.email}</div>

      <div className="pt-10">
        <PaymentInfo />
      </div>

      <div className="pt-10">
        <Payments />
      </div>

    </Container>
  );
}

export function PaymentInfo() {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      paymentMethods {
        brand
        last4
      }
    }
  `;

  const getPaymentMethods = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending, refetch } = useQuery<any>({ queryKey: ['paymentMethods'], queryFn: getPaymentMethods, retry: 0 })

  const { mutate: removePaymentMethod, isPending: isRemoving } = useMutation({
    mutationFn: async () => {
      const mutation = `
        mutation Mutation {
          removePaymentMethod
        }
      `;

      await gqlClient.current?.request(mutation);
    },
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      console.log(e)
    }
  });

  let loading = null;
  if (isPending) {
    loading =  (
      <div className="flex flex-col items-center justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  let methodComps = null;
  if (data) {
    methodComps = data.paymentMethods.map((paymentMethod: any) => {
      return (
        <div className="p-4 flex items-center justify-between bg-gray-50 rounded-lg" key={paymentMethod.last4}>
          <div className="flex items-center">
            <span><PaymentIcon type={paymentMethod.brand} format="flatRounded" width={40} /></span>
            <span className="pl-2 text-xs">⏺⏺⏺⏺</span> <span>{ paymentMethod.last4 }</span>
          </div>
          <button
            className="text-red-700 font-semibold text-sm flex items-center disabled:text-gray-300"
            onClick={async () => {
              if (window.confirm('Remove payment method?')) {
                removePaymentMethod()
              }
            }}
            disabled={isRemoving}
          >
            {!!isRemoving && ( <Loader2 className="mr-2 h-4 w-4 animate-spin" /> )}
            Remove
          </button>
        </div>
      )
    });
  }

  return (
    <div>
      <div className="text-xl font-medium mb-2">Payment Info</div>

      { loading
        ? loading
        : (
          methodComps?.length
          ? methodComps
          : (
            <div>
              <Link
                to="/dashboard/account/payment"
                className="items-center px-3 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50"
              >Add Payment Method</Link>
            </div>
          )
        )
      }
    </div>
  )
}

export function Payments() {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      payments {
        id
        description
        date
        amount
        status
      }
    }
  `;

  const getPayments = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending } = useQuery<any>({ queryKey: ['payments'], queryFn: getPayments, retry: 0 })

  let loading = null;
  if (isPending) {
    loading = (
      <div className="flex flex-col items-center justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  let paymentsComps = null;
  if (data) {
    paymentsComps = <AccountChargesTable charges={data.payments} />
  }

  return (
    <div>
      <div className="text-xl font-medium">Payment History</div>

      { 
        loading ? loading : paymentsComps
      }
    </div>
  )
}

export function PaymentItem() {

  return (
    <div>

    </div>
  )
}

export default Account;
