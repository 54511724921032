import { MapPin } from "lucide-react";
import Container from "../Container";

export default function StartWithBizDetails(props: any) {

  const { data } = props;

  const images = data.images.map((url: string) => {
    return (
      <div style={{width: 276}} className="mr-3" key={url}>
        <img src={url}
          className="rounded-2xl" alt=""
        />
      </div>
    )
  });

  const mapsUrl = `https://maps.google.com/?q=${data.address} ${data.city} ${data.province} ${data.postcode}`

  return (
    <div>
      <Container>

        <div className="text-center text-3xl">
          Promote your local business at <span className="dark:text-white text-primary font-bold">{data.name}</span>
        </div>

        <a className="mt-2 flex flex-row align-center items-center text-sm justify-center" href={mapsUrl} target="_blank" rel="noreferrer">
          <div className="pr-2 text-primary">
            <MapPin size={20} />
          </div>

          <div>{data.address}, {data.city} {data.province}, {data.postcode}</div>
        </a>
      </Container>
      <div className="mt-10 w-full overflow-hidden md:rounded-2xl rounded-none">
        <div className="flex">
          <div className="flex custom-animation-scroll">
            { images }
          </div>
          <div className="flex custom-animation-scroll">
            { images }
          </div>
        </div>
      </div>

    </div>
  );
}
