import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAuth, sendSignInLinkToEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Container from "./Container";
import { useMutation } from "@tanstack/react-query";
import { inputClassNames } from "./config";


function SignupOptions() {
  const [hasAccount, setHasAccount] = useState(false);

  return (
    <>
      <Container>
        <EmailLinkForm hasAccount={hasAccount} />
      </Container>

      <div className="mt-6 bg-gray-50 py-6 px-4 text-center rounded-lg">
        <AuthFooter hasAccount={hasAccount} setHasAccount={setHasAccount}/>

        <div className="relative flex py-5 items-center px-10">
          <div className="flex-grow border-t border-gray-100"></div>
          <span className="flex-shrink mx-4 text-gray-400">or</span>
          <div className="flex-grow border-t border-gray-100"></div>
        </div>

        <GoogleSigninButton />
      </div>
    </>
  );
}

function AuthFooter(props: any) {
  const linkClassNames = 'font-bold text-primary'

  return (
    <>
      { props.hasAccount ? (
        <div>Don't have an account? <a href="#signup" onClick={(e) => {
            e.preventDefault();
            props.setHasAccount(false);
          }} className={linkClassNames}>Sign up</a>
        </div>
      ) : (
        <div>Already have an account? <a href="#login" onClick={(e) => {
            e.preventDefault();
            props.setHasAccount(true);
          }} className={linkClassNames}>Log in</a>
        </div>
      )}
    </>
  )
}

function EmailLinkForm(props: any) {
  const [searchParams] = useSearchParams();
  const [didSendEmail, setDidSendEmail] = useState(false);

  const location = window.location;

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${location.protocol}//${location.host}/completeAuth?=${searchParams}`,
    // This must be true.
    handleCodeInApp: true,
  };

  const mutation = useMutation({
    mutationFn: async ({email}: {email: string}) => {
      await sendSignInLinkToEmail(getAuth(), email, actionCodeSettings);

      window.localStorage.setItem('emailForAuth', email);
    },
    onSuccess: () => {
      setDidSendEmail(true);
    },
    onError: (e) => {
      console.log(e)
    }
  });

  return (
    <div>
      <div className="text-xl font-bold pb-8 text-center">{ props.hasAccount ? 'Log in to Pegboard' : 'Sign up to for Pegboard' }</div>

      {
        didSendEmail ? 
          (
            <>
              <div className="p-4 mb-4 rounded-lg bg-green-50" role="alert">
                <div><span className="font-bold text-green-800">Success!</span> We sent you an email with a magic link to login.</div>
              </div>
              <div className="pt-2 text-center">
                <a
                  href="#retry"
                  onClick={(e) => {e.preventDefault(); setDidSendEmail(false)}}
                  className="font-bold text-sm text-primary"
                >
                  Didn't recieve the email?
                </a>
              </div>
            </>
          ) :
          (
            <form className="w-full" onSubmit={(event: any) => {
              event.preventDefault();
              const email = event.target['email']?.value;
              mutation.mutate({ email });
            }}>
              <div className="mx-auto flex max-w-screen-sm items-center space-x-2 md:flex-row md:space-y-0">
                <div className="relative w-full">
                  <label
                    htmlFor="emailbang"
                    className="dark:text-gray-300 mb-2 hidden text-sm font-medium text-gray-900"
                  >
                    Email address
                  </label>

                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      className="dark:text-gray-400 h-5 w-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                  </div>

                  <input
                    className={[inputClassNames, 'pl-10'].join(' ')}
                    placeholder="Enter your email"
                    type="email"
                    id="email"
                    required={true}
                    disabled={!!mutation.isPending}
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50"
                    disabled={!!mutation.isPending}
                  >
                    { !!mutation.isPending && ( 
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    )}

                    { props.hasAccount ? 'Log in' : 'Sign up' }
                  </button>

                </div>
              </div>
            </form>
          )
      }

      { mutation.isError && (
        <div className="p-4 mt-4 text-red-800 rounded-lg bg-red-50" role="alert">
          { mutation.error.message }
        </div>
      )}
    </div>
  )
}

function GoogleSigninButton() {
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async () => {
      await signInWithPopup(getAuth(), provider);
    },
    onSuccess: () => {
      navigate('/dashboard');
    },
    onError: (e) => {
      console.log(e)
    }
  });

  return (
    <div className="flex items-center justify-center dark:bg-gray-800">
      <button className="px-4 py-2 border bg-white flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200" onClick={() => mutation.mutate()}>
        <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
        <span className="font-medium">Continue with Google</span>
      </button>
    </div>
  )

}

export default SignupOptions;
