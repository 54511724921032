import { useParams, Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AuthContext } from '../AuthProvider';
import LoadingView from '../Loading-View';
import Container from '../Container';
import BackButton from '../components/back-arrow';
import { useQuery } from "@tanstack/react-query";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import StatusBadge from '@/StatusBadge';


export default function AdminBoardDetails() {
  const params = useParams();
  const { gqlClient } = useContext(AuthContext);

  const getAdById = async () => {
    const document = `
      query Query($boardId: ID!) {
        adminBoard(boardId: $boardId) {
          id
          name
          status
          description
          image {
            url
          }
        }
      }
    `;

    const variables = {
      boardId: params.id
    }

    return gqlClient.current?.request({ document, variables })
  };

  const { data: resp, isPending, error } = useQuery<Record<string, any>>({
    queryKey: [`getBoardById-${params.id}`], queryFn: getAdById, retry: 0
  });

  if (isPending) {
    return <LoadingView />
  }

  if (error) {
    alert(error);
    return null;
  }

  const board = (resp as any).adminBoard;

  console.log(board);

  return(
    <Container>
      <BackButton to="/admin/boards" label="Back to Dashboard" />

      <div className="mb-10">
        <div className="mt-2">
          <div className="overflow-hidden">
            <div className="font-medium text-xl">{ board.name }</div>
            <div className="">{ board.description }</div>
            <div className="my-2"><StatusBadge label={board.status} /></div>
          </div>

          <div className="flex space-x-2 mt-2">

            <a target="_blank" href={board.image?.url} rel="noreferrer">
              <div
                className="w-28 h-28 rounded-lg overflow-hidden bg-cover bg-center bg-no-repeat"
                style={{backgroundImage: `url(${board.image?.url})`}}>
              </div>
            </a>
          </div>
        </div>

      </div>

      <div className="font-semibold pb-4">QR Scans (By Date)</div>
      <BoardStats board={board} />

    </Container>
  );
}

const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}

function BoardStats(props: any) {
  const { gqlClient } = useContext(AuthContext);
  const { board }  = props;

  const binTypes = {
    day: 'day',
    hour: 'hour',
  };

  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 3);

  const [start] = useState(oneMonthAgo);
  const [end] = useState(today);
  const [bin] = useState(binTypes.hour);

  const getBoardStats = async (start: Date, end: Date, bin: string) => {
    const document = `
      query Query($boardId: ID!, $start: AWSDateTime!, $end: AWSDateTime!, $bin: String) {
        adminBoardStats(boardId: $boardId, start: $start, end: $end, bin: $bin) {
          data
        }
      }
    `;

    const variables = {
      boardId: board.id,
      start: start.toISOString(),
      end: end.toISOString(),
      bin
    };

    return gqlClient.current?.request({ document, variables })
  };

  const { data: resp, isPending, error } = useQuery<Record<string, any>>({
    queryKey: [`getBoardStats-${board.id}`], queryFn: () => getBoardStats(start, end, bin), retry: 0
  });

  if (isPending) {
    return <LoadingView />
  }

  if (error) {
    console.log(error);
    // alert(error);
    return null;
  }

  const data = JSON.parse((resp as any).adminBoardStats.data);

  const bars: Array<any> = [];
  data?.ad_names?.forEach((ad_name: string) => {
    bars.push(<Bar dataKey={ad_name} name={ad_name} key={ad_name} fill={stringToColour(ad_name)} stackId="a" />);
  });

  return (
    <div>
      { !data?.items?.length ? (
        <div className="font-medium text-gray-500 text-center py-10 text-sm">No data yet</div>
      ) : (
        <ResponsiveContainer width={'100%'} height={300}>
          <BarChart
            data={data?.items}
            margin={{
              right: 0,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />

            <XAxis dataKey="name" tickFormatter={(date) => {
              return new Date(date).toLocaleDateString('en-us', { weekday: undefined, hour:"numeric", minute: "numeric", year:undefined, month:"numeric", day:"numeric"})
              if (bin === 'hour') {
                return new Date(date).toLocaleDateString('en-us', { weekday: undefined, hour:"numeric", year:undefined, month:"numeric", day:"numeric"})
              }
              return new Date(date).toLocaleDateString('en-us', { weekday: undefined, year:"numeric", month:"numeric", day:"numeric"})
            }} />

            <YAxis
              tickFormatter={(count) => {
                return Math.round(count).toString()
              }}
              allowDecimals={false}
              width={20}
            />

            <Tooltip
              labelFormatter={(date) => {
                if (bin === 'hour') {
                  return new Date(date).toLocaleDateString('en-us', { weekday: 'short', hour:"numeric", year:undefined, month:"numeric", day:"numeric"})
                }
                return new Date(date).toLocaleDateString('en-us', { weekday: undefined, year:"numeric", month:"numeric", day:"numeric"})
              }}
            />
            <Legend />
            { bars }
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
