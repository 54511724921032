import Container from "./Container";

function Features() {
  return (
    <Container>
      <div className="flex-row-reverse justify-between space-y-6 pt-4 text-gray-600 md:flex md:gap-6 md:space-y-0 lg:gap-12">
        <div className="md:w-2/3">
          <h2 className="dark:text-white my-2 text-2xl font-bold text-gray-700 md:text-4xl">
            Make your point of sale clean and clutter free
          </h2>

          <p className="dark:text-gray-300 text-gray-600">
            Continue to support your local neighboring shops and businesses
            without cluttering your counters with tacky and out-dated stacks of
            postcards and flyers.
          </p>

          {/*
          <div className="pb-4 pt-8">
            <a
              href="/start?host=wip"
              className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-base font-semibold text-white">
                Get Started
              </span>
            </a>
          </div>
          */}
        </div>

        <div className="my-4 justify-center overflow-hidden rounded-xl">
          <img
            src="images/clean-01.jpg"
            alt="Local advertisement on countertop tablet"
          />
        </div>

        {/*
	<div className="my-4 rounded-xl overflow-hidden md:w-1/2 justify-center">
	  <img src="images/cluttered.webp" />
	</div>
	*/}
      </div>

      <div className="dark:divide-gray-700 dark:border-gray-700 mt-16 grid divide-x divide-y divide-gray-100 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 sm:grid-cols-3 lg:grid-cols-3 lg:divide-y-0 xl:grid-cols-3">
        <div className="dark:bg-gray-800 group relative bg-white transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
          <div className="relative space-y-8 p-8 py-12">
            <div className="overflow-hidden rounded-xl">
              <img
                src="images/featured-01.jpeg"
                width="512"
                height="512"
                alt="featured 1"
              />
            </div>

            <div className="space-y-2">
              <h5 className="dark:text-white text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
                Sleek Touch Screen
              </h5>
              <p className="dark:text-gray-300 text-gray-600">
                Small enough to sit next to your point-of-sale system and
                automatically loop through your pre-approved advertisements.
              </p>
            </div>
          </div>
        </div>

        <div className="dark:bg-gray-800 group relative bg-white transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
          <div className="relative justify-center space-y-8 p-8 py-12">
            <div className="overflow-hidden rounded-xl">
              <img
                src="images/track-engagement.jpeg"
                alt="stats"
              />
            </div>

            <div className="space-y-2">
              <h5 className="dark:text-white text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
                Track Engagement
              </h5>
              <p className="dark:text-gray-300 text-gray-600">
                See how many people interact with an ad for both you and the
                advertiser.
              </p>
            </div>
          </div>
        </div>

        <div className="dark:bg-gray-800 group relative bg-white transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
          <div className="relative space-y-8 p-8 py-12">
            <div className="overflow-hidden rounded-xl">
              <img
                src="images/cluttered-02.jpg"
                alt="Cluttered countertop with local business ads"
              />
            </div>

            <div className="space-y-2">
              <h5 className="dark:text-white text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
                No More Cluttered Counters
              </h5>
              <p className="dark:text-gray-300 text-gray-600">
                Move all of the counter top clutter onto a single, small screen
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Features;
