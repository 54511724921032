import { useContext } from "react";
import { AuthContext } from "@/AuthProvider";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "@/components/LoadingSpinner";
import { Link, Navigate } from "react-router-dom";
import Container from "@/Container";
import StatusBadge from "@/StatusBadge";

export default function AdminBoards() {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      adminBoards {
        id
        name
        status
        description
        image {
          url
        }
      }
    }
  `;

  const getBoards = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending, error } = useQuery<Record<string, any>>({ queryKey: [`adminGetBoards`], queryFn: getBoards, retry: 0, staleTime: 0 });

  if (error) {
    console.log(error);
    // return <Navigate to='/admin' />;
  }

  if (isPending) {
    return (
      <div className="w-full h-40 flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  const comps: any[] = [];

  data?.adminBoards?.forEach((board: any) => {
    comps.push(<AdminBoardRow data={board} key={board.id} />)
  });

  return (
    <Container>
      <div className="flex flex-row justify-between items-center pb-2 mb-6">
        <div className="text-2xl font-semibold">Admin Boards</div>
      </div>

      <div className="space-y-4">
        { comps }
      </div>
    </Container>
  );
}


export function AdminBoardRow(props: any) {
  const { data } = props;

  return (
    <div>
      <Link to={`/admin/boards/${data.id}`}>
        <label className="flex flex-row items-start grow cursor-pointer">

          <div className="w-28 h-28 overflow-hidden rounded-lg flex-shrink-0">
            <div
              className='w-full h-full bg-cover bg-center bg-no-repeat'
              style={{backgroundImage: `url(${data.image?.url})`}}>
            </div>
          </div>

          <div className="pl-4 grow">
            <div className="mb-2"><StatusBadge label={data.status} /></div>
            <div className="font-bold">{ data.name }</div>
            <div className="text-sm pt-2 max-w-xs">{ data.description }</div>
          </div>
        </label>
      </Link>
    </div>
  )
}
