import { Link } from "react-router-dom";

function BackButton(props: any) {
  const {to, label} = props;

  return (
    <Link to={to}
      className="flex items-center mb-1 pr-4 py-2 font-medium inline-flex text-sm text-gray-600">
      <BackArrow /><span className="pl-2">{label}</span>
    </Link>
  );
}

function BackArrow() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sc-cxNIbT"><path d="M9.793 5.793l-5.5 5.5a1 1 0 000 1.414l5.5 5.5 1.414-1.414L7.414 13H20v-2H7.414l3.793-3.793-1.414-1.414z" fill="currentColor"></path></svg>
  );
}

export default BackButton;
