import Container from "./Container";

function AppFooter() {
  return (
    <footer className="py-10 md:py-10 pt-32">
      <Container>
        <div className="m-auto md:w-10/12 lg:w-8/12 xl:w-6/12">
          <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
            <div className="m-auto w-10/12 space-x-4 space-y-6 text-center sm:mt-auto">
              {/*
              <span className="flex justify-between text-gray-800 dark:text-white">
                <Link to="/terms" className="text-sm font-medium">Terms of use</Link>
                <a href="#" className="text-sm font-medium">Contact Us</a>
                <Link to="/wipdemo" className="text-sm font-medium">Terms of use</Link>
              </span>
              */}

              <span className="dark:text-gray-400 block text-sm font-medium text-gray-300">
                PegBoard.ai &copy; <span id="year">2024</span>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default AppFooter;
