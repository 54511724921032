import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate, Link, useSearchParams } from "react-router-dom";
import { AuthContext } from "../AuthProvider";
import Container from "../Container";
import LoadingSpinner from "../components/LoadingSpinner";
import StatusBadge from "../StatusBadge";
import { Checkbox } from "@/components/ui/checkbox";


function AdminAdReview() {
  const [searchParams] = useSearchParams();
  const { gqlClient } = useContext(AuthContext);

  const adId = searchParams.get('adId')

  const [message, setMessage] = useState('')

  const selectedBoardsIds = useRef(new Set());

  const document = `
    query Query($adId: ID!) {
      adminAd(adId: $adId) {
        id
        name
        paid
        price
        status
        statusDetails
        target {
          url
        }
        image {
          url
        }
        video {
          url
        }
        boardIds
      }
    }
  `;

  const variables = {
    adId,
  };

  const getAdById = async () => {
    return gqlClient.current?.request({ document, variables })
  };

  const { data, isPending, error, refetch } = useQuery<Record<string, any>>({ queryKey: [`adminGetAd-${adId}`], queryFn: getAdById, retry: 0, staleTime: 0 });

  const approveOrReject = useMutation({
    mutationFn: async ({ approved }: {approved: boolean}) => {

      const mutation = `
        mutation Mutation($adId: ID!, $approved: Boolean!, $message: String) {
          adminReviewAd(adId: $adId, approved: $approved, message: $message)
        }
      `;

      const variables = {
        adId,
        approved,
        message,
      };

      await gqlClient.current?.request(mutation, variables);
    },
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e);
    }
  });

  const updatePublished = useMutation({
    mutationFn: async () => {

      const mutation = `
        mutation Mutation($adId: ID!, $boardIds: [String!]) {
          adminPublishAd(adId: $adId, boardIds: $boardIds)
        }
      `;

      const boardIds = Array.from(selectedBoardsIds.current.values());

      const variables = {
        adId,
        boardIds
      };

      await gqlClient.current?.request(mutation, variables);
    },
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e);
    }
  });

  useEffect(() => {
    setMessage(data?.adminAd?.statusDetails ?? '');
    selectedBoardsIds.current = new Set(data?.adminAd?.boardIds)
  }, [data])

  const onBoardSelection = useCallback((boardId: string, selected: boolean) => {
    if (selected) {
      selectedBoardsIds.current.add(boardId);
    } else {
      selectedBoardsIds.current.delete(boardId);
    }
  }, []);

  if (isPending) {
    return (
      <div className="w-full h-40 flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <Navigate to='/admin' />;
  }

  const ad = data.adminAd;

  const  { status, published } = ad;

  return (
    <Container>

      <Link to={`/admin`} className="flex items-center pr-4 py-2 font-semibold inline-flex text-sm text-gray-800">
        <svg className="w-5 h-5 rtl:rotate-180 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
        </svg>
        Back
      </Link>

      <div className="flex flex-row justify-between items-center pb-2 mb-6">
        <div className="text-2xl font-semibold">Ad Review</div>
      </div>

      <div className="mb-10">
        <div className="border-b border-gray-200 text-sm font-semibold text-gray-800 pb-1 mb-4">Ad Content</div>

        <div className="">
          <div className="mb-5">
            <div className="text-xs text-gray-600 overflow-hidden">Ad Name (Internal):</div>
            <div className="font-medium overflow-hidden">{ ad.name }</div>
          </div>

          <div className="mb-5">
            <div className="text-xs text-gray-600 overflow-hidden">Target URL:</div>
            <a href={ad.target?.url} target='_blank' rel='noreferrer' className="text-primary font-medium overflow-hidden">{ ad.target?.url }</a>
          </div>
        </div>

        <div className="pt-6 items-start space-y-6 lg:space-y-0 lg:flex lg:space-x-4">

          <div className="w-2/3 md:w-1/3 aspect-adv overflow-hidden rounded-lg flex-shrink-0">
            <a target="_blank" href={ad.image?.url} rel="noreferrer">
              <div
                className='w-full h-full bg-cover bg-center bg-no-repeat'
                style={{backgroundImage: `url(${ad.image?.url})`}}>
              </div>
            </a>
          </div>

          <div className="w-2/3 md:w-1/3 aspect-adv overflow-hidden rounded-lg flex-shrink-0">
            <div>
              <div className="border-purple-100 rounded-xl overflow-hidden">
                <video className="h-full" muted autoPlay controls>
                  <source src={ad.video?.url} />
                </video>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="mb-10">
        <div className="border-b border-gray-200 text-sm font-semibold text-gray-800 pb-1 mb-4">Review &amp; Approval</div>

        <StatusBadge label={status} />

        <div className="flex flex-row items-start mt-2">
          <BoardsToPublish onBoardSelection={onBoardSelection} selectedBoardIds={ad.boardIds} />
        </div>

        <textarea id="message" rows={2} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Review notes if applicable" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>

        <div className="pt-3 text-center flex space-x-4 justify-between">
          <button
            onClick={() => {
              if (window.confirm('Confirm REJECT?')) {
                approveOrReject.mutate({ approved: false });
              }
            }}
            className="px-4 py-2 font-semibold rounded-md text-white text-sm bg-red-700 whitespace-nowrap disabled:opacity-50"
          >
            Reject
          </button>

          { published ? (
            <button
              onClick={() => {
                if (window.confirm('Confirm UN-PUBLISH')) {
                  updatePublished.mutate()
                }
              }}
              className="px-4 py-2 font-semibold rounded-md text-white text-sm bg-red-700 whitespace-nowrap disabled:opacity-50"
            >
              Un-Publish
            </button>
          ) : (
            <button
              onClick={() => {
                if (window.confirm('Confirm Changes?')) {
                  updatePublished.mutate()
                }
              }}
              className="px-4 py-2 font-semibold rounded-md text-white text-sm bg-primary whitespace-nowrap disabled:opacity-50"
            >
              Publish
            </button>
          )}
        </div>
      </div>

      <Payment ad={ad} refetchAd={refetch} />

    </Container>
  );
}

function Payment(props: any) {
  const { gqlClient } = useContext(AuthContext);

  const { ad } = props;

  const document = `
    query Query($adId: ID!) {
      adminChargesForAd(adId: $adId) {
        id
        description
        amount
        status
        date
      }
    }
  `;

  const variables = {
    adId: ad.id
  }

  const getChargesForAd = async () => {
    return gqlClient.current?.request({ document, variables})
  };

  const { data, isPending, refetch } = useQuery({ queryKey: [`amdinChargesForAd-${ad.id}`], queryFn: getChargesForAd, retry: 0 });

  const chargeMutation = useMutation({
    mutationFn: async () => {

      const mutation = `
        mutation Mutation($adId: ID!) {
          adminCharge(adId: $adId)
        }
      `;

      const variables = {
        adId: ad.id,
      };

      await gqlClient.current?.request(mutation, variables);
    },
    onSuccess: () => {
      refetch();
      props.refetchAd();
    },
    onError: (e) => {
      alert(e);
    }
  });

  if (isPending) {
    return (
      <div className="flex grow items-center justify-center py-20">
        <LoadingSpinner />
      </div>
    );
  }

  const charges = (data as any)?.adminChargesForAd;

  return (
    <div className="mb-10">
      <div className="border-b border-gray-200 text-sm font-semibold text-gray-800 pb-1 mb-4">Payment</div>

      <div className="flex flex-row">
        { ad.paid ? (
          <StatusBadge label='Paid' color='green' />
        ) : (
          <StatusBadge label='Not&nbsp;Paid' color='red' />
        )}

        <div className="flex flex-row items-center pl-4">
          <div className="text-gray-800 pr-1 text-sm font-semibold">Price:</div>
          <div className="font-bold">${ ad.price }</div>
        </div>
      </div>

      <ChargesForAd charges={charges} />

      <div className="mt-10 flex justify-end">
        { (!ad.paid && ad.price > 0) && (
          <button
            className="px-4 py-2 font-semibold rounded-md text-white text-sm bg-primary whitespace-nowrap disabled:opacity-50"
            onClick={() => {
              if (window.confirm('Confirm charge customer?')) {
                chargeMutation.mutate()
              }
            }}
          >
            Charge ${ad.price}
          </button>
        )}
      </div>
    </div>
  );
}

function ChargesForAd(props: any) {
  const { charges } = props;

  const comps: any[] = [];
  charges.forEach((item: any) => {
    comps.push(<ChargeRow charge={item} key={item.id} />);
  });

  return (
    <div className="divide-y grow">
      {comps}
    </div>
  )
}

function ChargeRow(props: any) {
  const { charge } = props;

  return (
    <div className="mt-4">
      <div className="flex flex-row grow justify-between">
        <div className="pr-2">{ (new Date(charge.date)).toLocaleDateString() }</div>
        <div className="font-medium">${ charge.amount }</div>
      </div>
      <div className="font-medium text-sm">{ charge.description }</div>
    </div>
  );
}

function BoardsToPublish(props: any) {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      adminBoards {
        id
        name
        status
        description
        image {
          url
        }
      }
    }
  `;

  const getAdminBoards = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending } = useQuery({ queryKey: ['boards'], queryFn: getAdminBoards, retry: 0 })

  if (isPending) {
    return (
      <div className="flex grow items-center justify-center py-20">
        <LoadingSpinner />
      </div>
    );
  }

  const boardComps: any[] = [];
  (data as any)?.adminBoards.forEach((item: any) => {
    const isChecked = props.selectedBoardIds.indexOf(item.id) >= 0

    boardComps.push(
      <BoardRow
        data={item}
        key={item.id}
        onBoardSelection={props.onBoardSelection}
        isChecked={isChecked}
      />
    );
  });

  return (
    <div className="space-y-2 grow mb-4">
      {boardComps}
    </div>
  )
}


function BoardRow(props: any) {
  const { data } = props;

  const [isChecked, setIsChecked] = useState(props.isChecked)

  const onCheckedChanged = useCallback((checked: boolean) => {
    props.onBoardSelection(data.id, checked);

    setIsChecked(checked);
  }, []);

  let className = isChecked ? 'bg-purple-50' : 'bg-white';
  className += " rounded-lg"

  return (
    <div className={className}>
      <label className="flex flex-row items-start py-2 grow">

        <Checkbox
          className="mr-4 ml-2 rounded-sm border-gray-800 checked:bg-gray-800"
          onCheckedChange={(checked: boolean) => { onCheckedChanged(checked) }}
          checked={isChecked}
        />

        <div className="w-28 h-28 overflow-hidden rounded-lg flex-shrink-0">
          <div
            className='w-full h-full bg-cover bg-center bg-no-repeat'
            style={{backgroundImage: `url(${data.image?.url})`}}>
          </div>
        </div>

        <div className="pl-4 grow">
          <div className="font-bold">{ data.name }</div>
          <div className="text-sm pt-2 max-w-xs">{ data.description }</div>
        </div>
      </label>
    </div>
  )
}


export default AdminAdReview;
