import Container from "./Container";

function HeroSection() {
  return (
    <div className="relative" id="home">
      <Container>
        <div className="relative ml-auto pt-24">
          <div className="mx-auto text-center lg:w-2/3">
            <h1 className="dark:text-white text-5xl font-bold text-gray-900 md:text-6xl xl:text-7xl">
              Promote local without the clutter on a digital{" "}
              <span className="dark:text-white text-primary">display</span>
            </h1>

            <p className="dark:text-gray-300 mt-8 text-gray-700">
              Keep your counter tops clean and clutter free by moving all of the
              physical business cards, promotions and local advertisements onto
              a simple touch screen.
            </p>

            <div className="mt-8 flex flex-wrap justify-center gap-x-6 gap-y-4">
              <div className="my-4 justify-center overflow-hidden rounded-xl">
                <img
                  src="images/hero-01.jpeg"
                  alt="Big counter with clean and sleek digital display"
                />
              </div>

              {/*
	      <a
		href="#getstarted"
		className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
		>
		<span className="relative text-base font-semibold text-white"
		>Get started</span
		>
	      </a>
	      <a
		href="#"
		className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max"
		>
		<span
		  className="relative text-base font-semibold text-primary dark:text-white"
		>Learn more</span
		>
	      </a>
		*/}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HeroSection;
