import { useEffect } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import Container from "./Container";
import LoadingSpinner from './components/LoadingSpinner';


function CompleteSignIn() {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    if (!isSignInWithEmailLink(auth, window.location.href)) {
      navigate('/');
      return;
    }

    const email = window.localStorage.getItem('emailForAuth');

    if (!email) {
      // User opened the link on a different device. To prevent session fixation attacks
      navigate('/');
      return;
    }

    signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        window.localStorage.removeItem('emailForAuth');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        navigate('/dashboard')
      })
      .catch((error) => {
        console.log(error);
        redirect('/')
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  });

  return (
    <div className="relative m-auto w-full md:w-2/3 space-y-4 pt-24">
      <Container>
        <div className="flex justify-center items-center flex-col align-center h-40">
          <div className="pb-6 font-semibold">Logging in</div>
          <LoadingSpinner />
        </div>
      </Container>
    </div>
  );
}

export default CompleteSignIn;
