import Container from "./Container";

function AppHeader() {
  const pathname = window.location.pathname;
  const showGetStarted = pathname !== '/getstarted'

  return (
    <nav className="dark:border-white/5 z-10 w-full border-b border-black/5 lg:border-transparent">
      <Container>
        <div className="relative flex flex-wrap items-center justify-between gap-6 py-3 md:gap-0 md:py-4">
          <div className="relative z-20 flex w-full justify-between md:px-0">
            <a
              href="/"
              aria-label="logo"
              className="flex items-center space-x-2"
            >
              <div aria-hidden="true" className="flex space-x-1">
                <div className="dark:bg-white h-3 w-3 rounded-full bg-gray-900"></div>
                <div className="h-6 w-1 bg-primary"></div>
              </div>
              <span className="text-l dark:text-white font-bold text-gray-900">
                PegBoard.ai
              </span>
            </a>

            { showGetStarted && (
            <div>
              <a
                href="/getstarted?host=wipcm"
                className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span className="relative text-sm font-semibold text-white">
                  {" "}
                  Get Started
                </span>
              </a>
            </div>
            )}
          </div>
        </div>
      </Container>
    </nav>
  );
}

export default AppHeader;

/*
function OverflowMenu() {
  const links = [
    {
      to: "/#features",
      label: "Features",
    },
    {
      to: "/#solution",
      label: "Solution",
    },
    {
      to: "/#reviews",
      label: "Reviews",
    },
  ];

<script>
    const navlinks = document.querySelector("#navlinks");
    const hamburger = document.querySelector("#hamburger");
    const layer = document.querySelector("#navLayer");
    const links = [...navlinks.querySelector("ul").children];

    function toggleNavlinks() {
	if (isToggled) {
	    navlinks.classList.add("!visible", "!scale-100", "!opacity-100", "!lg:translate-y-0");
	    hamburger.classList.add("toggled");
	    layer.classList.add("origin-top", "scale-y-100");
	} else {
	    navlinks.classList.remove("!visible", "!scale-100", "!opacity-100", "!lg:translate-y-0");
	    hamburger.classList.remove("toggled");
	    layer.classList.remove("origin-top", "scale-y-100");
	}
    }
    hamburger.addEventListener("click", () => {
	isToggled = !isToggled;
	toggleNavlinks();
    });

    links.forEach((link) => {
	link.addEventListener("click", () => {
	    isToggled = !isToggled;
	    toggleNavlinks();
	});
    });
</script>

  return (
    <div>
      <div className="relative flex max-h-10 items-center lg:hidden">
        <div className="mt-12 lg:mt-0">
          <a
            href="/register"
            className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
          >
            <span className="relative text-sm font-semibold text-white">
              {" "}
              Get Started
            </span>
          </a>
        </div>

        <button
          aria-label="humburger"
          id="hamburger"
          className="relative -mr-6 p-6"
        >
          <div
            aria-hidden="true"
            id="line"
            className="dark:bg-gray-300 m-auto h-0.5 w-5 rounded bg-sky-900 transition duration-300"
          ></div>
          <div
            aria-hidden="true"
            id="line2"
            className="dark:bg-gray-300 m-auto mt-2 h-0.5 w-5 rounded bg-sky-900 transition duration-300"
          ></div>
        </button>
      </div>
      <div
        id="navLayer"
        aria-hidden="true"
        className="dark:bg-gray-900/70 fixed inset-0 z-10 h-screen w-screen origin-bottom scale-y-0 bg-white/70 backdrop-blur-2xl transition duration-500 lg:hidden"
      ></div>
      <div
        id="navlinks"
        className="dark:border-gray-700 dark:bg-gray-800 dark:shadow-none invisible absolute left-0 top-full z-20 w-full origin-top-right translate-y-1 scale-90 flex-col flex-wrap justify-end gap-6 rounded-3xl border border-gray-100 bg-white p-8 opacity-0 shadow-2xl shadow-gray-600/10 transition-all duration-300 lg:visible lg:relative lg:flex lg:w-7/12 lg:translate-y-0 lg:scale-100 lg:flex-row lg:items-center lg:gap-0 lg:border-none lg:bg-transparent lg:p-0 lg:opacity-100 lg:shadow-none"
      >
        <div className="dark:text-gray-200 w-full text-gray-600 lg:w-auto lg:pr-4 lg:pt-0">
          <ul className="flex flex-col gap-6 tracking-wide lg:flex-row lg:gap-0 lg:text-sm">
            {links.map((link) => (
              <li>
                <a
                  href={link.to}
                  className="dark:hover:text-white block transition hover:text-primary md:px-4"
                >
                  <span>{link.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-12 lg:mt-0">
          <a
            href="/register"
            className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
          >
            <span className="relative text-sm font-semibold text-white">
              {" "}
              Get Started
            </span>
          </a>
        </div>

        <div className="mt-12 lg:mt-0">
          <a
            href="/register"
            className="relative flex h-9 w-full items-center justify-center px-4 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
          >
            <span className="relative text-sm font-semibold text-primary">
              Sign In
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
*/
