import { useQuery } from "@tanstack/react-query";
import { inputClassNames, inputLabelClassNames } from "./config";
import Container from "./Container";
import { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "./AuthProvider";
import LoadingSpinner from "./components/LoadingSpinner";
import { useMutation } from "@tanstack/react-query";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import { Loader2, FileImage, FileVideo } from 'lucide-react';
import BackButton from "./components/back-arrow";


export function NewAd() {
  return (
    <Container>
      <BackButton to='/dashboard' label='Back to Dashboard' />

      <PutAd />
    </Container>
  )
}

export function EditAd() {
  const [searchParams] = useSearchParams();

  const params = useParams();
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query($id: ID!) {
      myAd(id: $id) {
        id
        name
        paid
        target {
          url
        }
        image {
          url
        }
        video {
          url
        }
      }
    }
  `;

  const variables = {
    id: params.id
  }

  const getAdById = async () => {
    return gqlClient.current?.request({ document, variables })
  };

  const { data, isPending, error } = useQuery<Record<string, any>>({ queryKey: [`getAdById-${params.id}`], queryFn: getAdById, retry: 0 })

  if (isPending) {
    return (
      <div className="w-full h-40 flex justify-center items-center">
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    return <Navigate to='/dashboard' />;
  }

  const ad = data.myAd

  let backTo = '/dashboard';
  let backToLabel = 'Back to Dashboard';
  if (searchParams.get('back') === 'ad-details') {
    backTo = `/dashboard/ads/details/${ad.id}`
    backToLabel = 'Back to Ad Details'
  }

  return (
    <Container>
      <BackButton to={backTo} label={backToLabel} />

      <PutAd ad={ad} />
    </Container>
  )
}


function PutAd(props: any) {
  const navigate = useNavigate();

  const [reviewAd, setReviewAd] = useState(false);
  const ad = useRef(props.ad)
  const [formData, setFormData] = useState({} as any);

  const { gqlClient } = useContext(AuthContext);

  const getImageUploadUrl = async (fileType: string) => {
    const document = `
      query Query($id: ID!, $fileType: String!) {
        getAdImageUploadUrl(id: $id, fileType: $fileType)
      }
    `;

    const variables = {
      id: ad.current?.id,
      fileType,
    }

    const resp = await gqlClient.current?.request({ document, variables }) as any;

    return resp.getAdImageUploadUrl;
  };

  const getVideoUploadUrl = async (fileType: string) => {
    const document = `
      query Query($id: ID!, $fileType: String!) {
        getAdVideoUploadUrl(id: $id, fileType: $fileType)
      }
    `;

    const variables = {
      id: ad.current?.id,
      fileType,
    }

    const resp = await gqlClient.current?.request({ document, variables }) as any;

    return resp.getAdVideoUploadUrl;
  };

  const { mutate: submitAd, isPending } = useMutation({
    mutationFn: async () => {
      const mutation = `
        mutation Mutation($adInput: AdInput!) {
          putAd(
            adInput: $adInput
          ) {
            id
            name
            paid
            target {
              url
            }
          }
        }
      `;

      const { name, imageFile, videoFile, targetUrl } = formData;

      const variables = {
        adInput: {
          id: ad.current?.id,
          name,
          targetUrl,
        }
      };

      const resp = await gqlClient.current?.request(mutation, variables);

      // save the id here so if there's a failure in upload we don't keep creating new ads
      // we just update the current
      const adResp = (resp as any).putAd

      ad.current = adResp;

      if (imageFile) {
        const fileUploadUrl = await getImageUploadUrl(imageFile.type);

        await fetch(fileUploadUrl, {
          method: 'PUT',
          headers: { 'Content-Type': imageFile.type },
          body: imageFile
        });
      }

      if (videoFile) {
        const fileUploadUrl = await getVideoUploadUrl(videoFile.type);

        await fetch(fileUploadUrl, {
          method: 'PUT',
          headers: { 'Content-Type': videoFile.type },
          body: videoFile
        });
      }
    },
    onSuccess: () => {
      return navigate(`/dashboard/ads/${ad.current.id}/payment`)
    },
    onError: (e) => {
      alert('Error submitting your ad.');
      console.log(e);
    }
  });

  return (
    <>
      <div
        className={reviewAd ? 'absolute opacity-0 w-0 h-0 top-0 right-0 overflow-hidden' : ''}
      >
        <AdForm ad={ad} onContinue={(data: any) => {
          setFormData(data);
          setReviewAd(true);
          window.scrollTo(0, 0);
        }} />
      </div>

      <div
        className={!reviewAd ? 'absolute opacity-0 w-0 h-0 top-0 right-0 overflow-hidden' : ''}
      >
        <AdReview
          ad={ad}
          formData={formData}
          onCancel={() => setReviewAd(false) }
          onSubmit={() => submitAd(formData)}
          isSubmitting={isPending}
        />
      </div>
    </>
  )
}

function AdForm(props: any) {
  const { ad, onContinue } = props;

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(ad.current?.image?.url);
  const [videoPreview, setVideoPreview] = useState<string | null>(ad.current?.video?.url);

  useEffect(() => {
    if (imageFile) {
      setImagePreview(URL.createObjectURL(imageFile));
    }

    if (videoFile) {
      setVideoPreview(URL.createObjectURL(videoFile));
    }

    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }

      if (videoPreview) {
        URL.revokeObjectURL(videoPreview);
      }
    }
  }, [imageFile, videoFile])

  const handleImageFileChange = (event: any) => {
    if (!event.target.files[0]) {
      return;
    }

    setImageFile(event.target.files[0])
  };

  const handleVideoFileChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setVideoFile(file)
  };

  return (
    <>
      <div className="text-2xl font-bold">{props.ad.current ? 'Edit your Ad' : 'Create a new Ad'}</div>

      <form action='' onSubmit={(event: any) => {
        event.preventDefault();
        const name = event.target['name']?.value;
        const targetUrl = event.target['url']?.value;

        onContinue({name, targetUrl, imageFile, videoFile, imagePreview, videoPreview})
      }}>

        <div className="pt-6 items-start space-y-6 md:space-y-0 md:flex md:space-x-4">
          <ImageBox ad={ad} preview={imagePreview} handleFileChange={handleImageFileChange} />
          <VideoBox ad={ad} preview={videoPreview} handleFileChange={handleVideoFileChange} />
        </div>

        <div className="pt-6">
          <label className={inputLabelClassNames} htmlFor="url">Target URL for the QR Code</label>
          <input className={inputClassNames} type="url" id="url" name='url' defaultValue={ad.current?.target?.url} />
        </div>

        <div className="pt-6">
          <label className={inputLabelClassNames} htmlFor="name">Ad Title
            <span className="text-sm text-gray-500"> (Internal, only visible to you)</span>
          </label>
          <input className={inputClassNames} type="text" id="name" name='name' defaultValue={ad.current?.name} required />
        </div>

        <div className="pt-10 text-center flex justify-end">
          <button
            type="submit"
            className="px-6 py-3 font-semibold leading-6 shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50"
          >
            Continue
          </button>
        </div>

      </form>
    </>
  )
}


function AdReview(props: any) {
  const { formData, onSubmit, onCancel, isSubmitting } = props;
  const { imagePreview, videoPreview } = formData;

  const width = videoPreview ? 'w-2/6' : 'w-full'

  return (
    <div>
      <div className="text-2xl font-bold">Review your Ad</div>

      <div className="items-start pt-10">
        <label className="font-semibold" htmlFor="ad-boards">Going live at:</label>
      </div>

      <SelectBoardsToPublish />

      <div className="pt-6 items-start flex space-x-4">

        <div className={`${width}`}>
          <label className={inputLabelClassNames} htmlFor="image" id="image">Image File</label>
          <div className={`overflow-hidden rounded-xl aspect-adv`}>
            <div
              className='w-full h-full bg-cover bg-center bg-no-repeat'
              style={{backgroundImage: `url(${imagePreview})`}}>
            </div>
          </div>
        </div>

        <div className={`${width}`}>
          <label className={inputLabelClassNames} htmlFor="image" id="image">Video File</label>
          <div className={`overflow-hidden rounded-xl aspect-adv`}>
            <div className="rounded-xl overflow-hidden">
              <video className="h-full" muted controls key={videoPreview} >
                <source src={videoPreview} />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <label className={inputLabelClassNames} htmlFor="url">Target URL for the QR Code</label>
        <div><a href={formData.targetUrl} target='_blank' rel="noreferrer" className="text-blue-500 text mt-2">{ formData.targetUrl }</a></div>
      </div>

      <div className="pt-6">
        <label className={inputLabelClassNames} htmlFor="name">Ad Title
          <span className="text-sm text-gray-500"> (Internal, only visible to you)</span>
        </label>
        <div className="">{ formData.name }</div>
      </div>

      {/*
      <div className="bg-gray-50 py-2 px-4 rounded-lg my-6">
        { !props.ad.current?.paid ? (
          <>
            <div className="flex flex-row justify-center items-center mt-2">
              <div className="h-8 flex items-center px-4 rounded-l-md font-bold text-left bg-gray-200 text-gray-800 text-sm">
                <span>Rate</span>
              </div>
              <div className="flex items-center h-8 px-4 rounded-r-md font-bold text-left bg-lime-200 text-green-700">
                <span>$5.99/wk</span>
              </div>
            </div>

            <div className="text-center text-sm text-green-600 font-medium mt-2">
              Total of <span className="font-bold">$23.96</span> billed after your ad goes live.
            </div>
          </>
        ) : (
          <div className="text-center text-sm text-green-600 font-medium mt-2">
            You've already paid for this ad! Your new ad will be distributed after approval.
          </div>
        )}
      </div>
      */}

      <div className="pt-10 text-center flex justify-end">
        <button onClick={onCancel} className="mr-10 font-semibold py-3 px-4">Cancel</button>

        <button
          onClick={() => {
            if (window.confirm('Confirm submitting changes.')) {
              onSubmit();
            }
          }}
          className="px-4 py-3 font-semibold leading-6 shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50 flex items-center"
          disabled={isSubmitting}
        >
          {!!isSubmitting && ( <Loader2 className="mr-2 h-4 w-4 animate-spin" /> )}
          Submit
        </button>
      </div>
    </div>
  );
}

function BoardRow(props: any) {
  const { data } = props;

  return (
    <div className="flex flex-row items-start py-4">
      <div className="overflow-hidden rounded-lg w-24 h-24 flex-shrink-0">
        <div
          className='w-full h-full bg-cover bg-center bg-no-repeat'
          style={{backgroundImage: `url(${data.image.url})`}}>
        </div>
      </div>

      <div className="pl-4">
        <div className="font-bold">{ data.name }</div>
        <div className="text-sm pt-2 max-w-xs">{ data.description }</div>
      </div>
    </div>
  )
}

function SelectBoardsToPublish(props: any) {
  const { gqlClient } = useContext(AuthContext);

  const document = `
    query Query {
      availableBoards {
        id
        name
        description
        image {
          url
        }
      }
    }
  `;

  const getAvailableBoards = async () => {
    return gqlClient.current?.request({ document })
  };

  const { data, isPending } = useQuery({ queryKey: ['availableBoards'], queryFn: getAvailableBoards, retry: 0 })

  if (isPending) {
    return (
      <div className="flex flex-col items-center justify-center py-20">
        <LoadingSpinner />
      </div>
    );
  }

  const boardComps: any[] = [];
  (data as any)?.availableBoards.forEach((item: any) => {
    boardComps.push(<BoardRow data={item} key={item.id} />);
  });

  return (
    <div className="divide-y">
      {boardComps}
    </div>
  )
}


function ImageBox(props: any) {
  const { preview, ad, handleFileChange } = props;

  return (
    <div>
      <label className={inputLabelClassNames} htmlFor="image" id="image">Image File</label>

      <p className="text-xs text-gray-500 dark:text-gray-400">Supported File Types: <code>png</code>, <code>jpeg</code>, <code>gif</code></p>
      <p className="text-xs text-gray-500 dark:text-gray-400">Minimum Dimensions: <code>720px x 1280px</code></p>

      <div className="flex items-center mt-2 relative w-80 aspect-adv">
        { preview ? (
          <div className="flex flex-col w-full h-full rounded-xl">
            <label
              key={preview}
              htmlFor="dropzone-file" 
              className="flex w-full h-full rounded-lg cursor-pointer bg-no-repeat bg-cover bg-center"
              style={{backgroundImage: `url(${preview})`}}
            >
            </label>
            <div className="absolute bottom-1 right-1 overflow-hidden rounded-lg">
              <QRCode value='example' size={75} />
            </div>
          </div>
        ) : (
            <label htmlFor="dropzone-file" className="flex w-full h-full flex-col items-center justify-center border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <FileImage size={40} strokeWidth={1.5} />

                <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to Upload Image</span>
                </p>
              </div>
            </label>
          )}

        <input id="dropzone-file" type="file" name='ad-image' className="absolute top-0 right-0 bottom-0 left-0 opacity-0" required={!ad.current?.id} onChange={handleFileChange} />
      </div>
    </div>
  );
}


function VideoBox(props: any) {
  const { preview, ad, handleFileChange } = props;

  return (
    <div>
      <label className={inputLabelClassNames} htmlFor="image" id="image">Video File</label>

      <p className="text-xs text-gray-500 dark:text-gray-400">Supported File Types: <code>mp4</code>, <code>mov</code></p>
      <p className="text-xs text-gray-500 dark:text-gray-400">Minimum Dimensions: <code>720px x 1280px</code></p>

      <div className="flex items-center mt-2 relative w-80 aspect-adv">
        { preview ? (
          <div>
            <div className="border-purple-100 rounded-xl overflow-hidden">
              <video className="h-full" muted autoPlay loop key={preview} >
                <source src={preview} />
              </video>
            </div>

            <div className="absolute bottom-1 right-1 overflow-hidden rounded-lg">
              <QRCode value='example' size={75} />
            </div>
          </div>
        ) : (
          <label htmlFor="dropzone-file" className="flex w-full h-full flex-col items-center justify-center border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <FileVideo size={40} strokeWidth={1.5} />

              <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to Upload Video</span>
              </p>
            </div>
          </label>
        )}

        <input id="dropzone-file" type="file" name='ad-video' className="absolute top-0 right-0 bottom-0 left-0 opacity-0" onChange={handleFileChange} />
      </div>
    </div>
  );
}
