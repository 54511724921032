import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/ui/table"


export function AccountChargesTable(props: any) {
  const { charges } = props;

  return (
    <Table>
      <TableBody>
        {charges.map((charge: any) => (
          <TableRow key={charge.id}>
            <TableCell className="pl-0">{(new Date(charge.date)).toLocaleDateString() }</TableCell>
            <TableCell className="text-right">${charge.amount}</TableCell>
            <TableCell>
              <div className="flex">
                <div className={`flex items-center justify-center overflow-hidden rounded-md py-1 px-2 border border-green-200 bg-green-100 text-green-800`}>
                  <div className={`font-semibold text-xs`}>{charge.status}</div>
                </div>
              </div>
            </TableCell>
            <TableCell className="pr-0">{charge.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default AccountChargesTable;
