import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: 'AIzaSyBujNfJGlcRGmSn0d8T29vHBodxtdpiUWk',
  authDomain: 'auth.pegboard.ai',
  projectId: 'pegboard-5fab9',
  storageBucket: 'pegboard-5fab9.appspot.com',
  messagingSenderId: '437505898915',
  appId: '1:437505898915:web:1f3375baea4cffc02cc216',
  measurementId: 'G-K56FMM0ZFJ'
};

declare global {
  interface Window {
    firebaseApp: any;
  }
}

// Initialize Firebase
window.firebaseApp = initializeApp(firebaseConfig, 'Pegboard');
// const analytics = getAnalytics(window.firebaseApp);

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
