import { useContext, useState } from 'react';
import { AuthContext } from "./AuthProvider";
import { Navigate, Outlet, Link } from 'react-router-dom';
import AppFooter from './AppFooter';
import Container from './Container';
import { Menu, CircleX } from 'lucide-react';
import { Toaster } from '@/components/ui/toaster';

export default function ProtectedRoutes() {
  const { authToken, loading } = useContext(AuthContext);

  if (loading) {
    return (<></>);
  }

  if (!authToken) {
    return <Navigate to='/start' />;
  }

  return (
    <div className="dark:bg-gray-900 bg-white">
      <ProtectedBgGradient />
      <PrivateRoutesHeader />
      <div className="relative m-auto w-full lg:w-2/3 space-y-4 pt-10">
        <Outlet />
      </div>
      <Toaster />
      <AppFooter />
    </div>
  )
}

function PrivateRoutesHeader() {
  return (
    <nav className="dark:border-white/5 m-auto z-10 w-full lg:w-2/3 border-b border-black/5 lg:border-transparent">
      <Container>
        <div className="relative flex flex-wrap items-center justify-between gap-6 md:gap-0">
          <div className="relative z-20 flex w-full justify-between md:px-0">
            <a
              href="/dashboard"
              aria-label="logo"
              className="flex items-center space-x-2 py-3"
            >
              <div aria-hidden="true" className="flex space-x-1">
                <div className="dark:bg-white h-3 w-3 rounded-full bg-gray-900"></div>
                <div className="h-6 w-1 bg-primary"></div>
              </div>
              <span className="text-l dark:text-white font-bold text-gray-900">
                PegBoard.ai
              </span>
            </a>

            <HamburgerMenu />
          </div>
        </div>
      </Container>
    </nav>
  )
}

function HamburgerMenu() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <button onClick={() => setShowMenu(!showMenu)}
        className="hidden py-3 pl-2 sm:hidden"
      >
        <Menu />
      </button>

      <div
        className="block py-3 pl-2 sm:block space-x-2"
      >
        <Link to="/dashboard" className="font-medium py-2 px-2 text-sm">Dashboard</Link>
        <Link to="/dashboard/account" className="font-medium py-2 pl-2 text-sm">Account</Link>
      </div>
      {
        !!showMenu && (
          <div
            className="fixed top-0 right-0 bottom-0 left-0 bg-white"
          >
            <div className="flex justify-end">
              <button className='p-3' onClick={() => setShowMenu(false)}>
                <CircleX />
              </button>
            </div>
            <div className="text-center flex flex-col space-y-4 pt-10">
              <a href="/dashboard" className="text-2xl font-medium py-2 px-4">Dashboard</a>
              <a href="/dashboard/account" className="text-2xl font-medium py-2 px-4">Account</a>
            </div>
          </div>
        )
      }
    </>
  );
}

function ProtectedBgGradient() {
  return (
    <div
      aria-hidden="true"
      className="dark:opacity-20 absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40"
    >
      <div className="dark:from-blue-700 h-20 bg-gradient-to-br from-primary to-purple-400 blur-[60px]"></div>
      <div className="dark:to-indigo-600 h-10 bg-gradient-to-r from-cyan-400 to-sky-300 blur-[60px]"></div>
    </div>
  )
}
