import Container from "./Container";

function CallToAction() {
  return (
    <div className="relative py-16">
      <div
        aria-hidden="true"
        className="dark:opacity-20 absolute inset-0 m-auto grid h-max w-full grid-cols-2 -space-x-52 opacity-40"
      >
        <div className="dark:from-blue-700 h-56 bg-gradient-to-br from-primary to-purple-400 blur-[106px]"></div>
        <div className="dark:to-indigo-600 h-32 bg-gradient-to-r from-cyan-400 to-sky-300 blur-[106px]"></div>
      </div>

      <Container>
        <div className="relative">
          <div className="flex items-center justify-center -space-x-2"></div>
          <div className="m-auto mt-6 space-y-6 md:w-8/12 lg:w-7/12">
            <h1 className="dark:text-white text-center text-4xl font-bold text-gray-800 md:text-5xl">
              Get Started Now
            </h1>
            <p className="dark:text-gray-300 text-center text-xl text-gray-600">
              Save on physical cards and flyers, no more cluttered counters and
              get more engagement.
            </p>
            <div className="flex flex-wrap justify-center gap-6">
              <a
                href="/getstarted?host=wipcm"
                className="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span className="dark:text-dark relative text-base font-semibold text-white">
                  Get Started
                </span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CallToAction;
