import "./App.css";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Terms from "./Terms";
import Home from "./Home";
import WipDemo from "./wip-demo";
import StartWithHost from "./StartWithHost";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import BgGradient from "./components/bg-gradient";
import CompleteAuth from "./CompleteAuth";
import Dashboard from "./Dashboard";
import ProtectedRoutes from "./ProtectedRoute";
import AuthProvider from "./AuthProvider";
import { NewAd, EditAd } from "./CreateAd";
import ReviewAd from "./Review-Ad";
import ReviewAdPayment, { ReviewAdPaymentSuccess } from "./Review-Ad-Payment";
import AdminDashboard from "./admin/Admin";
import AdminRoutes from "./AdminRoute";
import AdminAdReview from "./admin/AdminAdReview";
import AdminBoards from "./admin/AdminBoards";
import AdminBoardDetails from "./admin/AdminBoardDetails";
import AdDetails from "./Ad-Details";
import AccountAddPayment, { AccountAddPaymentSuccess } from "./Account-Add-Payment";
import Account from "./Account";
import DisplayBoard from "./DisplayBoard";
import GetStarted from "./GetStarted";


const queryClient = new QueryClient()

function Layout() {
  return (
    <div className="dark:bg-gray-900 bg-white">
      <BgGradient />
      <AppHeader />
      <Outlet />
      <AppFooter />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="admin" element={<AdminRoutes />}>
              <Route index element={<AdminDashboard />} />
              <Route path="ad-review" element={<AdminAdReview />} />
              <Route path="boards" element={<AdminBoards />} />
              <Route path="boards/:id" element={<AdminBoardDetails />} />
            </Route>
            <Route path="dashboard" element={<ProtectedRoutes />}>
              <Route index element={<Dashboard />} />
              <Route path="account" element={<Account />} />
              <Route path="account/payment" element={<AccountAddPayment />} />
              <Route path="account/payment/success" element={<AccountAddPaymentSuccess />} />
              <Route path="ads/details/:id" element={<AdDetails />} />
              <Route path="ads/new" element={<NewAd />} />
              <Route path="ads/:id" element={<EditAd />} />
              <Route path="ads/:id/review" element={<ReviewAd />} />
              <Route path="ads/:id/payment" element={<ReviewAdPayment />} />
              <Route path="ads/:id/payment/success" element={<ReviewAdPaymentSuccess />} />
            </Route>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="getstarted" element={<GetStarted />} />
              <Route path="terms" element={<Terms />} />
              <Route path="start" element={<StartWithHost />} />
              <Route path="completeAuth" element={<CompleteAuth />} />
            </Route>
            <Route path="boards/:boardId/ads" element={<DisplayBoard />} />
            <Route path="wipdemo" element={<WipDemo />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
