function BgGradient() {
  return (
    <div
      aria-hidden="true"
      className="dark:opacity-20 absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40"
    >
      <div className="dark:from-blue-700 h-56 bg-gradient-to-br from-primary to-purple-400 blur-[106px]"></div>
      <div className="dark:to-indigo-600 h-32 bg-gradient-to-r from-cyan-400 to-sky-300 blur-[106px]"></div>
    </div>
  )
}

export default BgGradient;
