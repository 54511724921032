import Container from "./Container";

function Terms() {
  return (
    <Container>
      <div className="relative ml-auto space-y-4 pt-36">
        <p className="mb-2 text-3xl font-bold">Terms of Use</p>

        <p>
          These Terms of Use ("Terms") govern your use of PegBoard.ai, including
          all its subdomains, and any related services provided by PegBoard.ai
          ("we", "us", or "our"). By accessing or using our website, you agree
          to be bound by these Terms. If you do not agree to these Terms, please
          do not use our website.
        </p>

        <p className="text-xl font-bold">1. Use of Our Website</p>

        <p>
          1.1. You must be at least 18 years old to use our website. By using
          our website and agreeing to these Terms, you warrant and represent
          that you are at least 18 years of age.
        </p>

        <p>
          1.2. You are responsible for ensuring that all persons who access our
          website through your internet connection are aware of these Terms and
          comply with them.
        </p>

        <p>
          1.3. We reserve the right to modify or discontinue, temporarily or
          permanently, the website or any features or portions thereof without
          prior notice. We will not be liable to you or any third party for any
          modification, suspension, or discontinuance of the website or any part
          thereof.
        </p>

        <p className="text-xl font-bold">2. User Content</p>

        <p>
          2.1. Our website allows you to post, link, store, share, and otherwise
          make available certain information, text, graphics, videos, or other
          material ("User Content"). You are solely responsible for the User
          Content that you post on or through our website.
        </p>

        <p>
          2.2. By posting User Content on our website, you grant us the right
          and license to use, modify, publicly perform, publicly display,
          reproduce, and distribute such User Content on and through our
          website.
        </p>

        <p>
          2.3. You retain any and all of your rights to any User Content you
          submit, post, or display on or through our website, and you are
          responsible for protecting those rights.
        </p>

        <p className="text-xl font-bold">3. Intellectual Property Rights</p>

        <p>
          3.1. Our website and its original content (excluding User Content),
          features, and functionality are and will remain the exclusive property
          of PegBoard.ai and its licensors.
        </p>

        <p>
          3.2. Our website is protected by copyright, trademark, and other laws
          of both the United States and foreign countries. Our trademarks and
          trade dress may not be used in connection with any product or service
          without the prior written consent of PegBoard.ai.
        </p>

        <p className="text-xl font-bold">4. Prohibited Uses</p>

        <p>
          4.1. You may use our website only for lawful purposes and in
          accordance with these Terms. You agree not to use our website:
        </p>

        <p>
          In any way that violates any applicable federal, state, local, or
          international law or regulation.
        </p>

        <p>
          To impersonate or attempt to impersonate PegBoard.ai, a PegBoard.ai
          employee, another user, or any other person or entity.
        </p>

        <p>
          To engage in any other conduct that restricts or inhibits anyone's use
          or enjoyment of our website, or which, as determined by us, may harm
          PegBoard.ai or users of our website or expose them to liability.
        </p>

        <p className="text-xl font-bold">5. Limitation of Liability</p>

        <p>
          5.1. In no event shall PegBoard.ai, nor its directors, employees,
          partners, agents, suppliers, or affiliates, be liable for any
          indirect, incidental, special, consequential, or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the website; (ii) any conduct or content
          of any third party on the website; (iii) any content obtained from the
          website; and (iv) unauthorized access, use, or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence), or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </p>

        <p className="text-xl font-bold">6. Governing Law</p>

        <p>
          6.1. These Terms shall be governed and construed in accordance with
          the laws of [Your Country], without regard to its conflict of law
          provisions.
        </p>

        <p className="text-xl font-bold">7. Changes to These Terms</p>

        <p>
          7.1. We reserve the right, at our sole discretion, to modify or
          replace these Terms at any time. If a revision is material, we will
          try to provide at least 30 days' notice prior to any new terms taking
          effect. What constitutes a material change will be determined at our
          sole discretion.
        </p>

        <p className="text-xl font-bold">8. Contact Us</p>

        <p>
          8.1. If you have any questions about these Terms, please contact us.
        </p>

        <p>
          By using our website, you signify your acceptance of these Terms. If
          you do not agree to these Terms, please refrain from using our
          website.
        </p>
      </div>
    </Container>
  );
}

export default Terms;
