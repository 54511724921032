import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { Navigate, useParams, Link, useNavigate } from "react-router-dom";
import Container from "./Container";
import { AuthContext } from "./AuthProvider";
import LoadingSpinner from "./components/LoadingSpinner";


function ReviewAd() {
  const navigate = useNavigate();

  const params = useParams();
  const { gqlClient, user } = useContext(AuthContext);

  const hasPaymentInfo = user.current?.hasPaymentInfo;

  const submitForReview = useMutation({
    mutationFn: async ({ adId } : { adId: string }) => {

      const mutation = `
        mutation Mutation($adId: ID!) {
          submitAdForReview(adId: $adId)
        }
      `;

      const variables = {
        adId: adId,
      };

      await gqlClient.current?.request(mutation, variables);
    },
    onSuccess: () => {
      if (hasPaymentInfo) {
        return navigate(`/dashboard?code=ad-success`);
      }
      return navigate(`/dashboard/ads/${ad.id}/payment`)
    },
    onError: (e) => {
      alert(e);
    }
  });

  const document = `
    query Query($id: ID!) {
      myAd(id: $id) {
        id
        name
        target {
          url
        }
        image {
          url
        }
      }
    }
  `;

  const variables = {
    id: params.id
  };

  const getAdById = async () => {
    return gqlClient.current?.request({ document, variables })
  };

  const { data, isPending, error } = useQuery<Record<string, any>>({ queryKey: ['getAdById'], queryFn: getAdById, retry: 0, staleTime: 0 });

  if (isPending) {
    return (
      <div className="w-full h-40 flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <Navigate to='/dashboard' />;
  }

  const ad = data.myAd;

  const hasPaid = ad?.paid;

  return (
    <Container>

      <Link to={`/dashboard/ads/${ad.id}`} className="flex items-center mb-4 pr-4 py-2 font-semibold inline-flex text-sm text-gray-800">
        <svg className="w-5 h-5 rtl:rotate-180 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
        </svg>
        Back
      </Link>

      <div>
        <div className="flex flex-row items-start py-4">
          <div className="overflow-hidden rounded-lg">
            <div
              className='w-12 aspect-adv bg-cover bg-center bg-no-repeat'
              style={{backgroundImage: `url(${ad.image.url})`}}>
            </div>
          </div>

          <div className="pl-4">
            <div className="font-bold text-lg">{ ad.name }</div>
            <div>
              <a href={ad.target.url} target='_blank' rel='noreferrer' className="text-blue-500 text-sm">{ ad.target.url }</a>
            </div>
          </div>

        </div>
      </div>

      <hr className="h-0 border-gray-200 w-full my-4" />

      { hasPaid ? (
        <>
          <div className="flex flex-row justify-center items-center mt-2">
            <div className="h-8 flex items-center px-4 rounded-l-md font-bold text-left bg-gray-200 text-gray-800 text-sm">
              <span>Rate</span>
            </div>
            <div className="flex items-center h-8 px-4 rounded-r-md font-bold text-left bg-lime-200 text-green-700">
              <span>$5.99/wk</span>
            </div>
          </div>

          <div className="text-center text-sm text-green-600 font-medium mt-2">
            Total of <span className="font-bold">$23.96</span> billed after your ad goes live.
          </div>
        </>
      ) : (
        <div className="text-center text-sm text-green-600 font-medium mt-2">
          You've already paid for this ad! You new ad will be distributed upon approval.
        </div>
      )}

      <div className="pt-10 text-center">
        <button
          onClick={() => submitForReview.mutate({ adId: ad.id })}
          className="px-10 py-3 font-semibold leading-6 shadow rounded-md text-white bg-primary whitespace-nowrap disabled:opacity-50"
        >
          Submit for Review
        </button>
      </div>

    </Container>
  );
}

export default ReviewAd;
